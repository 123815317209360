import OpenSeadragon from "./OpenSeaDragon";
import Zones from "./zones";

const WorldMapService = class {
    static TOTAL_LAYERS = 8;
    static ASSET_ENDPOINT = 'https://cdn.brandonkorenek.com/file/sundaydrivers/html/';
    static cell_font = '18pt bold corbel';
    static block_font = '12pt corbel';

    static veiwer;
    static base_map;
    static currentLayer = 0;
    static overlays = {};
    static pois = [];
    static groupedPois = [];
    static blockPois = [];
    static mod_maps = [];
    static map_names = [];
    static poisEnabled = false;
    static grid = false;
    static zones = false;
    static min_step_cell = 8;
    static min_step_block = 8;
    static min_step_tile = 8;
    static rectLevel = 'cell';
    static rectX1 = 0;
    static rectY1 = 0;
    static rectX2 = 0;
    static rectY2 = 0;
    static saved_blocks = new Set();
    static saved_cells = {};
    static selected_blocks = new Set();
    static selected_cells = {};

    init(callback= null) {
        WorldMapService.base_map = new Map('', true);

        WorldMapService.overlays = {};
        WorldMapService.currentLayer = 0;
        WorldMapService.updateClip();

        WorldMapService.viewer = OpenSeadragon({
            element: document.getElementById("map_div"),
            tileSources:  WorldMapService.ASSET_ENDPOINT + "base/layer0.dzi",
            homeFillsViewer: true,
            showZoomControl: false,
            constrainDuringPan: true,
            visibilityRatio: 0.5,
            prefixUrl: "pzmap/html/openseadragon/images/",
            navigatorBackground: 'black',
            minZoomImageRatio: 0.5,
            maxZoomPixelRatio: 2 * WorldMapService.base_map.scale,
            minPixelRatio: .75
        });

        // Watch the viewport update event, this is beign used to draw grids and poi's
        WorldMapService.viewer.addHandler('update-viewport', function() {
            let ctx, c00, step_tile, step_block, step_cell,cell, block, tile_text_offset, tile_grid_offset;

            if(WorldMapService.poisEnabled || WorldMapService.zones || WorldMapService.grid) {
                ctx = WorldMapService.viewer.drawer.context;

                [c00, step_tile, step_block, step_cell] = WorldMapService.getCanvasOriginAndStep();

                [cell, block, tile_text_offset, tile_grid_offset] = WorldMapService.getGridLevel(ctx, step_cell, step_tile, step_block);
            }
            
            if(WorldMapService.grid && tile_text_offset) {
                WorldMapService.drawEditState(ctx, c00, step_tile, block);
            }
            
            if (tile_text_offset && WorldMapService.poisEnabled) {
                ctx.font = WorldMapService.block_font;
                WorldMapService.drawTilePois(ctx, c00, step_tile);
                if(WorldMapService.grid) {
                    ctx.strokeStyle = 'blue';
                    WorldMapService.drawGrid(ctx, c00, step_tile, 1);
                    if(tile_text_offset && WorldMapService.viewer.viewport.getZoom(true) > 849) {
                        ctx.fillStyle = 'blue';
                        ctx.font = WorldMapService.cell_font;
                        WorldMapService.drawCoord(ctx, c00, step_tile, 50);
                    }
                }
            }

            if (cell && !tile_text_offset && WorldMapService.grid) {
                ctx.strokeStyle = 'lime';
                WorldMapService.drawGrid(ctx, c00, step_cell * 10, 3);
                ctx.fillStyle = 'lime';
                ctx.font = WorldMapService.cell_font;
                WorldMapService.drawCoord(ctx, c00, step_cell * 10, 30, true);
            }

            if (cell && !tile_text_offset && WorldMapService.poisEnabled) {
                ctx.font = WorldMapService.cell_font;
                WorldMapService.drawBlockPois(ctx, c00, step_cell);
            }

            if(WorldMapService.zones) {
                WorldMapService.drawTierZones(ctx, c00, step_tile, block);
            }
        })

        WorldMapService.viewer.addHandler('canvas-press', function(event) {
            if (WorldMapService.grid && event.originalEvent.shiftKey) {
                let ctx = WorldMapService.viewer.drawer.context;
                let x = event.position.x * window.devicePixelRatio;
                let y = event.position.y * window.devicePixelRatio;
                let [c00, step_cell, step_block] = WorldMapService.getCanvasOriginAndStep();
                let [cell, block, cto, bto] = WorldMapService.getGridLevel(ctx, step_cell, step_block);
                if (cell) {
                    WorldMapService.rectLevel = 'cell';
                    [WorldMapService.rectX1, WorldMapService.rectY1] = WorldMapService.getGridXY(c00, step_cell, x, y);
                }
                WorldMapService.rectX2 = WorldMapService.rectX1;
                WorldMapService.rectY2 = WorldMapService.rectY1;
    
                WorldMapService.viewer.forceRedraw();
                WorldMapService.viewer.raiseEvent('update-viewport', {});
                event.preventDefaultAction = true;
            }
        })

        WorldMapService.viewer.addHandler('canvas-drag', function(event) {
            if (WorldMapService.grid && event.originalEvent.shiftKey) {
                let x = event.position.x * window.devicePixelRatio;
                let y = event.position.y * window.devicePixelRatio;
                let [c00, step_tile, step_block, step_cell] = WorldMapService.getCanvasOriginAndStep();

                if (WorldMapService.rectLevel == 'cell') {
                    const [rectX2, rectY2] = WorldMapService.getGridXY(c00, step_tile, x, y);
                    WorldMapService.rectX2 = rectX2;
                    WorldMapService.rectY2 = rectY2;
                }
                if (!event.originalEvent.shiftKey) {
                    WorldMapService.rectLevel = 0;
                }
    
                WorldMapService.viewer.forceRedraw();
                WorldMapService.viewer.raiseEvent('update-viewport', {});
                event.preventDefaultAction = true;
            }
        })
    
        WorldMapService.viewer.addHandler('canvas-release', function(event) {
            if (this.rectLevel) {
                if (this.grid && event.originalEvent.shiftKey) {
                    if (this.rectX1 != this.rectX2 || this.rectY1 != this.rectY2) {
                        let ctx = WorldMapService.viewer.drawer.context;
                        let x = event.position.x * window.devicePixelRatio;
                        let y = event.position.y * window.devicePixelRatio;
                        let [c00, step_cell, step_block] = this.getCanvasOriginAndStep();
                        let [cell, block, cto, bto] = this.getGridLevel(ctx, step_cell, step_block);
                        for (let i = Math.min(this.rectX1, this.rectX2); i <= Math.max(this.rectX1, this.rectX2); i++) {
                            for (let j = Math.min(this.rectY1, this.rectY2); j <= Math.max(this.rectY1, this.rectY2); j++) {
                                if (this.rectLevel == 'block') {
                                    this.flipBlock(i, j);
                                } else if (this.rectLevel == 'cell') {
                                    this.flipCell(i, j);
                                }
                            }
                        }
                    }
    
                    this.rectLevel = 0;
                    WorldMapService.viewer.forceRedraw();
                    WorldMapService.viewer.raiseEvent('update-viewport', {});
                    event.preventDefaultAction = true;
                }
                this.rectLevel = 0;
            }
        })
    
        WorldMapService.viewer.addHandler('canvas-click', function(event) {
            if (event.quick && WorldMapService.grid && event.originalEvent.shiftKey) {
                event.preventDefaultAction = true;
            }
        })

        let success_callback = function (retry) {
            let img = WorldMapService.viewer.world.getItemAt(0);
            if (img && img.getFullyLoaded()) {
                WorldMapService.base_map.tiles[0] = img;
                if (callback) {
                    callback();
                }
            } else {
                setTimeout(retry, 1, retry);
            }
        }
        success_callback(success_callback);
    }

    static togglePois(enabled) {
        this.poisEnabled = enabled;
        if (enabled) {
            WorldMapService.viewer.raiseEvent('update-viewport', {});
        } else {
            WorldMapService.viewer.forceRedraw();
        }
    }

    static toggleZones() {
        this.zones = !this.zones;
        if (this.zones) {
            WorldMapService.viewer.raiseEvent('update-viewport', {});
        } else {
            WorldMapService.viewer.forceRedraw();
        }
    }

    static makeKey(x, y) {
        return x + ',' + y;
    }

    static drawEditState(ctx, c00, step, is_block) {
        let coords = this.inScreenCoords(ctx, c00, step, 2);
        let [step_x, step_y] = coords.next().value;
        ctx.setTransform(0.5, 0.25, -0.5, 0.25, c00.x, c00.y);
    
        let color = 0;
        for (let [gx, gy, kx, ky] of coords) {
            let key = this.makeKey(kx, ky);
            if (this.selected_cells[key]) {
                color = 'rgba(255,255,255,0.5)';
            }
    
            if (color) {
                ctx.fillStyle = color;
                ctx.fillRect(kx * step, ky * step, step, step);
                color = 0;
            }
        }
    
        // Drag selecting area
        if (this.rectLevel) {
            let scale = 1.0;
    
            let x1 = Math.min(this.rectX1, this.rectX2);
            let y1 = Math.min(this.rectY1, this.rectY2);
            let x2 = Math.max(this.rectX1, this.rectX2);
            let y2 = Math.max(this.rectY1, this.rectY2);
    
            let width = Math.abs(x2 - x1) + 1; // Width of selected tiles
            let height = Math.abs(y2 - y1) + 1; // Height of selected tiles
    
            let numSelectedTiles = width * height; // Total number of selected tiles
    
            // Padding values
            let textPadding = 10;    // Padding around the text inside the background
            let rectPadding = 15;    // Padding between the text rectangle and the selected area
    
            // Text to be displayed
            let text = `${Math.floor(this.rectX1)}, ${Math.floor(this.rectY1)}   ${Math.floor(this.rectX2)}, ${Math.floor(this.rectY2)} (${numSelectedTiles})`;
    
            // Set font before measuring
            ctx.font = '50pt bold corbel';
    
            // Measure the text dimensions
            let textMetrics = ctx.measureText(text);
            let textWidth = textMetrics.width + textPadding * 2;  // Add padding to text width
            let textHeight = 50 + textPadding * 2;  // Approximate height based on font size + padding
    
            // Draw background rectangle behind text with padding
            ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';  // Semi-transparent black background
            ctx.fillRect((x1 * step) - textPadding, (y1 * step) - textHeight - rectPadding, textWidth, textHeight);
    
            // Draw the text itself with white color and padding
            ctx.fillStyle = 'white';
            ctx.fillText(text, x1 * step, y1 * step - rectPadding - textPadding);
    
            // Draw the filled rectangle for selected area, applying padding between text rectangle and selection
            ctx.fillStyle = 'rgba(255,255,255,0.5)';
            ctx.fillRect(x1 * step, y1 * step, width * step, height * step);
        }
    
        ctx.setTransform();
    }

    static drawTierZones(ctx, c00, step, is_block) {
        let coords = this.inScreenCoords(ctx, c00, step, 2);
        let [step_x, step_y] = coords.next().value;
        ctx.setTransform(0.5, 0.25, -0.5, 0.25, c00.x, c00.y);
        
        Zones.forEach(zone => {
            const colors = [
                "rgba(0, 255, 0, 0.5)",       // Green (Tier 1)
                "rgba(255, 255, 0, 0.5)",     // Yellow (Tier 2)
                "rgba(255, 128, 0, 0.5)",     // Bright Orange (Tier 3)
                "rgba(153, 32, 255, 0.5)",    // Purple (Tier 4)
                "rgba(255, 0, 0, 0.5)"        // Red (Tier 5)
            ];

            let x1 = Math.min(zone.x1, zone.x2);
            let y1 = Math.min(zone.y1, zone.y2);
            let x2 = Math.max(zone.x1, zone.x2);
            let y2 = Math.max(zone.y1, zone.y2);
    
            let width = Math.abs(x2 - x1) + 1;
            let height = Math.abs(y2 - y1) + 1;
    
            ctx.strokeStyle = colors[zone.tier - 1];
            ctx.lineWidth = 10; 
    
            ctx.strokeRect(x1 * step, y1 * step, width * step, height * step);

            // Set font style for the text
            ctx.font = 'bold 32px Arial';  // Adjust font size as needed
            ctx.fillStyle = colors[zone.tier - 1];  // Match the text color with the stroke color

            // Draw the 'Tier X' text just above the rectangle
            ctx.fillText('Tier ' + zone.tier, x1 * step, y1 * step - 10);  // Place text above the rect
        });
    
        ctx.setTransform();
    }
    
    
    

    // Retrieve the mod maps list and add them to the viewport
    static loadModMaps() {
        let xhttp = new XMLHttpRequest();
        xhttp.open("GET", WorldMapService.ASSET_ENDPOINT + "mod_maps/map_list.json", false);
        try {
            xhttp.send(null);
        } catch (error) {}
        if (xhttp.status === 200) {
            let map_names = JSON.parse(xhttp.responseText);

            map_names.forEach(name => WorldMapService.addMap(name));
        }
    }

    // Retrieve the mod maps list and add them to the viewport
    static removeModMaps() {
        this.mod_maps = [];
        
        this.updateClip();

    }

    static getGridXY(c00, step, x, y) {
        let gx = 0;
        let gy = 0;
        let cxx0 = c00.x + 2 * (c00.y - y);
        let cxy0 = c00.x - 2 * (c00.y - y);
        gx = Math.floor((x - cxx0)/step);
        gy = Math.floor((cxy0 - x)/step);
        return [gx, gy];
    }

    // Retrieve the centralized coordinate of a list of coordinates
    static getCentroid(coordinates) {
        const totalPoints = coordinates.length;

        if (totalPoints === 0) return null;

        const sums = coordinates.reduce((acc, coord) => {
            acc.x += coord[0];
            acc.y += coord[1];
            return acc;
        }, { x: 0, y: 0 });

        const centroidX = sums.x / totalPoints;
        const centroidY = sums.y / totalPoints;

        return [Math.floor(centroidX), Math.floor(centroidY)];
    }

    // Convert a number to its letter representation, used to convert cell coordinates to map coordinates.
    static numberToLetters(num) {
        let letters = '';
        while (num > 0) {
            let remainder = (num - 1) % 26;
            letters = String.fromCharCode(65 + remainder) + letters;
            num = Math.floor((num - 1) / 26);
        }
        return letters;
    }

    // Convert cell coordinates to map coordinates
    static cellToMapCoordinates(cellX, cellY) {
        let letterPart = this.numberToLetters(cellX + 1);
        let numberPart = cellY + 1; 
        return letterPart + numberPart;
    }

    // Convert tile coordinates to cell coordinates
    static tileToCell(x, y) {
        return [Math.floor(x / 30), Math.floor(y / 30)]
    }


    static grid2key(gx, gy) {
        return [(gx + gy) / 2, (gy - gx) / 2];
    }

    static rectIntersect(r1, r2) {
        let [x1, y1, w1, h1] = r1;
        let [x2, y2, w2, h2] = r2;
        return (x1 < x2 + w2) && (x2 < x1 + w1) && (y1 < y2 + h2) && (y2 < y1 + h1);
    }

    static textSize(ctx, text) {
        let m = ctx.measureText('-01234,56789');
        let ascent = m.actualBoundingBoxAscent;
        let descent = m.actualBoundingBoxDescent;
        let width = ctx.measureText(text).width;
        return [width, ascent, descent];
    }

    // Determine if two coordinates are with the x distance of one another
    static isWithinDistance(x1, y1, x2, y2, distance) {
        const dx = x2 - x1;
        const dy = y2 - y1;
        const euclideanDistance = Math.sqrt(dx * dx + dy * dy);

        return euclideanDistance <= distance;
    }

    // Update a poi from the grouped pois, used to adjust the color of selected shop groups
    static updateGroupedPoi(id, properties) {
        const existingPoiIndex = this.groupedPois.findIndex(searchingForPoi => id === searchingForPoi.id);
    
        if (existingPoiIndex === -1) {
            return;
        }
    
        const existingPoi = this.groupedPois[existingPoiIndex];
    
        this.groupedPois[existingPoiIndex] = {
            ...existingPoi, 
            ...properties,
        };
        WorldMapService.viewer.forceRedraw();
    }

    // Add a poi to be rendered to the map
    static addPoi(id, x, y, color, label, category = null, layer = 0, proximityThreshold = 7, png = false, labelColor = 'white') {
        const existingPoi = this.groupedPois.find(searchingForPoi => id === searchingForPoi);

        if(existingPoi) {
            console.error(`POI ${id} already exists`);
            return;
        }

        let foundGroupIndex = -1;

        for (let i = 0; i < this.groupedPois.length; i++) {
            const poi = this.groupedPois[i];
            if (poi.category === category && poi.label === label) {
                if (this.isWithinDistance(poi.x, poi.y, x, y, proximityThreshold)) {
                    foundGroupIndex = i;
                    break;
                }
            }
        }

        if (foundGroupIndex !== -1) {
            const foundGroup = this.groupedPois[foundGroupIndex];
            foundGroup.groupedCoordinates.push([x, y]);
            const newCentroid = this.getCentroid(foundGroup.groupedCoordinates);

            this.groupedPois[foundGroupIndex] = {
                ...foundGroup,
                x: newCentroid[0],
                y: newCentroid[1],
            };
        } else {
            this.groupedPois.push({
                id,
                x,
                y,
                color,
                label,
                category,
                layer,
                groupedCoordinates: [[x, y]],
                png,
                labelColor
            });
        }

        this.blockPois = this.groupBlockPois(this.groupedPois, 10);
    }

    // Capitalize the first letter of a string
    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Select a layer to be displayed on the map. 
    static selectLayer(layer) {
        this.updateMaps(layer);
    }

    // Update the cliping for overlays on the maps. 
    static updateClip() {
        WorldMapService.base_map.setClipFromOverlayMaps(WorldMapService.mod_maps);
        for (let i = 0; i < WorldMapService.mod_maps.length; i++) {
            WorldMapService.mod_maps[i].setClipFromOverlayMaps(WorldMapService.mod_maps.slice(i + 1));
        }
    }

    // Iterate the in screen coordinates based on a provide step. 
    static* inScreenCoords(ctx, canvasOrigin, step, border) {
        let canvasWidth = ctx.canvas.width;
        let canvasHeight = ctx.canvas.height;
        let step_x = step;
        let step_y = step;
        let y_start = 0;
        let y_inc = 1;
        step_x = step / 2;
        step_y = step / 4;
        y_inc = 2;
        yield [step_x, step_y];

        let gx0 = -Math.floor(canvasOrigin.x / step_x) - border;
        let gy0 = -Math.floor(canvasOrigin.y / step_y) - border;
        let dx0 = gx0 + gy0;

        for (let x = 0; x <= canvasWidth / step_x + border * 2 - 1; x++) {
            y_start = (dx0 + x) % 2;
            for (let y = y_start; y <= canvasHeight / step_y + border * 2 - 1; y+=y_inc) {
                let gx = gx0 + x;
                let gy = gy0 + y;
                let [kx, ky] = this.grid2key(gx, gy);
                yield [gx, gy, kx, ky];
            }
        }
    }

    // Add a mod map and update the display in the viewer
    static addMap(name) {
        if (name != '') {
            let pos = 0;
            for (pos = 0; pos < this.mod_maps.length; pos++) {
                if (name === this.mod_maps[pos].name) {
                    break;
                }
            }
            if (pos >= this.mod_maps.length) {
                let map = new Map(name);
                map.base_map = this.base_map;
                this.mod_maps.push(map);
                WorldMapService.updateClip();
                this.updateMaps(WorldMapService.currentLayer);
            }
        }
    }

    static positionItem(item, name, layer) {
        let pos = 0;
        for (let i = 0; i < WorldMapService.base_map.tiles.length; i++ ) {
            if (name == '' && layer == i) {
                WorldMapService.viewer.world.setItemIndex(item, pos);
                return;
            }
            if (![0, null, 'delete'].includes(WorldMapService.base_map.tiles[i])) {
                pos++;
            }
        }
        for (let i = 0; i < WorldMapService.mod_maps.length; i++ ) {
            for (let j = 0; j < WorldMapService.mod_maps[i].tiles.length; j++ ) {
                if (name == WorldMapService.mod_maps[i].name && layer == j) {
                    WorldMapService.viewer.world.setItemIndex(item, pos);
                    return;
                }
                if (![0, null, 'delete'].includes(WorldMapService.mod_maps[i].tiles[j])) {
                    pos++;
                }
            }
        }
    }

    // Group an array of pois based on proximity and catagory. 
    static groupBlockPois(pois, proximityThreshold = 7) {
        const groupedPois = [];

        pois.forEach((poi) => {
            let foundGroupIndex = -1;
            const [poiX, poiY] = this.tileToCell(poi.x, poi.y);

            for (let i = 0; i < groupedPois.length; i++) {
                const group = groupedPois[i];
                const [groupX, groupY] = this.tileToCell(group.x, group.y);
                if (group.category === poi.category) {
                    if (this.isWithinDistance(groupX, groupY, poiX, poiY, proximityThreshold)) {
                        foundGroupIndex = i;
                        break;
                    }
                }
            }

            if (foundGroupIndex !== -1) {
                const foundGroup = groupedPois[foundGroupIndex];
                foundGroup.groupedCoordinates.push([poi.x, poi.y]);
                const newCentroid = this.getCentroid(foundGroup.groupedCoordinates);

                groupedPois[foundGroupIndex] = {
                    ...foundGroup,
                    x: newCentroid[0],
                    y: newCentroid[1]
                };
            } else {
                groupedPois.push({
                    ...poi,
                    groupedCoordinates: [[poi.x, poi.y]],
                    label: this.capitalizeFirstLetter(poi.category)
                });
            }
        });

        return groupedPois;
    }

    // Show or hide the grid in the viewer. 
    static toggleGrid() {
        this.grid = !this.grid;
        
        this.viewer.forceRedraw();
    }

    // Draw a grid based on the provided step. 
    static drawGrid(ctx, c00, step, line_width) {
        ctx.lineWidth = line_width;
        let w = ctx.canvas.width;
        let h = ctx.canvas.height;
        let x1 = c00.x
        let y1 = c00.y
        let max_w = w;
        let min_h = 0;
        let x_shift = 0;
        let y_shift = 0;
        let y_step = step;
        x1 += 2 * c00.y;
        y1 -= c00.x / 2;
        max_w += 2 * h;
        min_h -= w / 2;
        x_shift = -2 * h;
        y_shift = w / 2;
        y_step = step / 2;
        ctx.beginPath();
        x1 -= Math.floor(x1 / step) * step;
        while (x1 <= max_w) {
            ctx.moveTo(x1, 0);
            ctx.lineTo(x1 + x_shift, h);
            x1 += step;
        }
        y1 += Math.ceil((h - y1) / step) * step;
        while (y1 >= min_h) {
            ctx.moveTo(0, y1);
            ctx.lineTo(w, y1 + y_shift);
            y1 -= y_step;
        }
        ctx.stroke();
    }

    // Draw the coordinates for a grid intersection
    static drawCoord(ctx, c00, step, yoffset, drawLetterCoords) {
        let coords = this.inScreenCoords(ctx, c00, step, 1);
        let [step_x, step_y] = coords.next().value;
        let xoffset = 4;
        ctx.setTransform(1, 0, 0, 1, c00.x, c00.y);
        for (let [gx, gy, kx, ky] of coords) {
            let cx = gx * step_x;
            let cy = gy * step_y;
            let text = `${kx}, ${ky}`;
            xoffset = - ctx.measureText(text).width / 2;
            ctx.fillText(text, cx + xoffset, cy + yoffset);
            if(drawLetterCoords) { 
                ctx.fillText(this.cellToMapCoordinates(kx, ky), cx + xoffset, cy + yoffset + 20);
            }
    
        }
        ctx.setTransform();
    }

    // Draw the block level grouped pois
    static drawBlockPois(ctx, c00, step) {
        let coords = this.inScreenCoords(ctx, c00, step, 1);
        let [step_x, step_y] = coords.next().value;

        ctx.setTransform(1, 0, 0, 1, c00.x, c00.y);
        for (let [gx, gy, kx, ky] of coords) {
            let cx = gx * step_x;
            let cy = gy * step_y;
            const poi = this.blockPois.find(poi => {
                let [tx, ty] = WorldMapService.tileToCell(poi.x, poi.y);
                return tx == kx && ky == ty;
            });
            if(poi && this.currentLayer == poi.layer) {
                this.drawPoi(poi, ctx, cx, cy, this.capitalizeFirstLetter(poi.label));
            }
        }
        ctx.setTransform();
    }

    // Draw the tile level pois
    static drawTilePois(ctx, c00, step) {
        let coords = this.inScreenCoords(ctx, c00, step, 1);
        let [step_x, step_y] = coords.next().value;

        ctx.setTransform(1, 0, 0, 1, c00.x, c00.y);
        for (let [gx, gy, kx, ky] of coords) {
            let cx = gx * step_x;
            let cy = gy * step_y;

            const poi = this.groupedPois.find(poi => {
                return poi.x == kx && ky == poi.y;
            });
            if(poi && this.currentLayer == poi.layer) {
                this.drawPoi(poi, ctx, cx, cy, poi.label, 8);
            }
        }
        ctx.setTransform();
    }

    // Draw a single poi at the provided destination
    static drawPoi(poi, ctx, cx, cy, label = false, size = 5) {
        if (label) {
            let text = poi.label;
            if (poi.groupedCoordinates && poi.groupedCoordinates.length > 1 && text.slice(-1) !== 's') {
                text += "s";
            }
            const textWidth = ctx.measureText(text).width;
            const textHeight = 16;
            const padding = 6;
            const backgroundColor = 'rgba(0, 0, 0, 0.5)';
            let textColor = 'white';
            if(poi.labelColor) {
                textColor = poi.labelColor;
            }
            const labelYOffset = 25;
    
            const rectX = cx - textWidth / 2 - padding;
            const rectY = cy - labelYOffset - (textHeight / 2 + padding);
            const rectWidth = textWidth + 2 * padding;
            const rectHeight = textHeight + 2 * padding;
    
            ctx.fillStyle = backgroundColor;
            ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
    
            ctx.fillStyle = textColor;
            const textX = cx - textWidth / 2;
            const textY = rectY + padding + textHeight / 2;
            ctx.fillText(text, textX + 5, textY + 5);
        }
    
        if (poi.png) { 
            const imgWidth = size * 5;
            const imgHeight = size * 5; 
            ctx.drawImage(poi.png, cx - imgWidth / 2, cy - imgHeight / 2, imgWidth, imgHeight);
        } else {
            const storedFillStyle = ctx.fillStyle;
            ctx.fillStyle = poi.color;
    
            const radius = size;
    
            ctx.save();
            ctx.translate(cx, cy);
            ctx.beginPath();
            ctx.arc(0, 0, radius, 0, Math.PI * 2);
            ctx.fill();
            ctx.restore();
    
            ctx.fillStyle = storedFillStyle;
        }
    }

    // Get the canvas origin based on zoom level as well as the step levels
    static getCanvasOriginAndStep() {
        let zm = WorldMapService.viewer.viewport.getZoom(true);
        zm = WorldMapService.viewer.world.getItemAt(0).viewportToImageZoom(zm);
        zm *= window.devicePixelRatio;
        let yshift = 0;
        let x0 = WorldMapService.base_map.x0 / WorldMapService.base_map.scale;
        let y0 = (WorldMapService.base_map.y0 - yshift) / WorldMapService.base_map.scale;
        let vp00 = WorldMapService.viewer.world.getItemAt(0).imageToViewportCoordinates(x0, y0);
        let c00 = WorldMapService.viewer.viewport.pixelFromPoint(vp00, true);
        c00.x *= window.devicePixelRatio;
        c00.y *= window.devicePixelRatio;

        let step_cell = WorldMapService.base_map.sqr * 30 * zm / WorldMapService.base_map.scale;
        let step_block = step_cell / 10;
        let step_tile = step_cell / 30;

        return [c00, step_tile, step_block, step_cell];
    }


    static getGridLevel(ctx, step_block, step_tile, step_cell) {
        ctx.font = WorldMapService.cell_font;
        let [block_width, block_ascent, block_descent] = WorldMapService.textSize(ctx, '-00,-00');
        let block_height = block_ascent + block_descent;
        let tile_text_offset = 0;
        let tile_grid_offset = 0;

        let block = 0;
        let tile = 0;
        let cell = 0;
        const poiZoomBlockAppearanceDepthModifier = 350;
        const poiTileZoomAppearanceDepthModifier = 150;

        if (step_cell >= WorldMapService.min_step_cell * window.devicePixelRatio) {
            cell = 1;
        }

        if (step_block >= WorldMapService.min_step_block * window.devicePixelRatio) {
            block = 1;
        }

        if (step_tile >= WorldMapService.min_step_tile * window.devicePixelRatio) {
            tile = 1;
        }

        let min_step_block_text = 8 + block_width + 2 * block_height;

        if (step_block >= min_step_block_text + poiZoomBlockAppearanceDepthModifier) {
            block = 3;
            let [cell_width, cell_ascent, cell_descent] = WorldMapService.textSize(ctx, '-0000,-0000');
            let block_height = cell_ascent + cell_descent;
            let min_step_tile_text = 0;

            tile_text_offset = 2 + cell_width / 2 + cell_ascent;
            if (cell_width > block_width) {
                min_step_tile_text = 8 + cell_width + 2 * (block_height + Math.max(0, block_height - (cell_width - cell_width) / 4));
            } else {
                min_step_tile_text = 8 + cell_width * + 2 * (block_height + Math.max(0, block_height - (cell_width - cell_width) / 4));
            }

            if (step_tile >= min_step_tile_text - poiTileZoomAppearanceDepthModifier) {
                tile_grid_offset = tile_text_offset + cell_descent + 2 + cell_ascent;
            }
        }
        return [block, tile, tile_text_offset, tile_grid_offset];
    }

    static updateMaps(layer) {
        WorldMapService.base_map.setBaseLayer(layer);
        WorldMapService.base_map.setOverlayLayer(WorldMapService.overlays, layer);
        for (let i = 0; i < WorldMapService.mod_maps.length; i++) {
            WorldMapService.mod_maps[i].setBaseLayer(layer);
            WorldMapService.mod_maps[i].setOverlayLayer(WorldMapService.overlays, layer);
            
        }
        WorldMapService.currentLayer = layer;
    }

    static toggleOverlay(type) {
        WorldMapService.overlays[type] = !WorldMapService.overlays[type];
        WorldMapService.updateMaps(WorldMapService.currentLayer);
    }
}

const Map = class {
    layers = 0;
    tiles = [];
    overlays = {};
    overlay_layer = 0;
    cell_rects = [];
    clip_list = [];

    constructor(name, is_base=false) {
        this.name = name;
        this.is_base = is_base;
        this.init();
    }

    static shiftClipList(clip_list, scale) {
        let clip_list_shift = [];
        let yshift = (192 * WorldMapService.currentLayer);

        for (let i = 0; i < clip_list.length; i++) {
            let points = [];
            for (let j = 0; j < clip_list[i].length; j++) {
                points.push({x: clip_list[i][j].x / scale, y: (clip_list[i][j].y - yshift) / scale})
            }
            clip_list_shift.push(points);
        }
        return clip_list_shift;
    }

    cell2pixel(cx, cy) {
        let x = this.x0;
        let y = this.y0;
        x += (cx - cy) * this.sqr * 150;
        y += (cx + cy) * this.sqr * 75;
        return {x: x, y: y};
    }

    getClipPoints(rects, remove=true) {
        let points = [];
        if (remove) {
            points.push({x: 0, y: 0});
            points.push({x: 0, y: this.h});
            points.push({x: this.w, y: this.h});
            points.push({x: this.w, y: 0});
        }
        points.push({x: 0, y: 0});
        for (let [x, y, w, h] of rects) {
            points.push(this.cell2pixel(x, y));
            points.push(this.cell2pixel(x + w, y));
            points.push(this.cell2pixel(x + w, y + h));
            points.push(this.cell2pixel(x, y + h));
            points.push(this.cell2pixel(x, y));
            points.push({x: 0, y: 0});
        }
        return points;
    }

    setClipFromOverlayMaps(maps) {
        this.clip_list = [this.getClipPoints(this.cell_rects, false)];
        for (let i = maps.length - 1; i >= 0; i--) {
            let rlist = [];
            for (let r of maps[i].cell_rects) {
                for (let b of this.cell_rects) {
                    if (WorldMapService.rectIntersect(b, r)) {
                        rlist.push(r);
                        break;
                    }
                }
            }
            if (rlist.length > 0) {
                this.clip_list.push(this.getClipPoints(rlist));
            }
        }
        
        for (let type of ['zombie', 'foraging']) {
            if (this.overlays[type]) {
                let clip_list = Map.shiftClipList(this.clip_list, this.info[type].scale);
                this.overlays[type].setCroppingPolygons(clip_list);
            }
        }
        for (let type of ['room', 'objects']) {
            if (this.overlays[type]) {
                let clip_list = Map.shiftClipList(this.clip_list, this.info[type].scale);
                this.overlays[type].setCroppingPolygons(clip_list);
            }
        }
    }

    getMapRoot() {
        if (this.is_base) { 
            return WorldMapService.ASSET_ENDPOINT;
        }
        return WorldMapService.ASSET_ENDPOINT + 'mod_maps/' + this.name + '/';
    }

    _load(type, layer) {
        if (WorldMapService.viewer) {
            let x = (WorldMapService.base_map.x0 - this.x0) / WorldMapService.base_map.scale;
            let y = (WorldMapService.base_map.y0 - this.y0) / WorldMapService.base_map.scale;
            let p = WorldMapService.viewer.world.getItemAt(0).imageToViewportCoordinates(x, y);
            let width = this.w / WorldMapService.base_map.w;
            if (type == 'base') {
                if (layer < this.layers && this.tiles[layer] == 0) {
                    this.tiles[layer] = null;
                    WorldMapService.viewer.addTiledImage({
                        tileSource: this.getMapRoot() + type + "/layer" + layer + ".dzi",
                        opacity: 1,
                        x: p.x,
                        y: p.y,
                        width: width,
                        success: (function (obj) {
                            if (this.tiles[layer] == 'delete') {
                                WorldMapService.viewer.world.removeItem(obj.item);
                                this.tiles[layer] = 0;
                            } else {
                                this.tiles[layer] = obj.item;
                                WorldMapService.positionItem(obj.item, this.name, layer);
                            }
                        }).bind(this),
                        error: (function (e) {
                            if (['delete', 0, null].includes(this.tiles[layer])) {
                                this.tiles[layer] = 0;
                            }
                        }).bind(this),
                    });
                }
                return
            }
            let shift = true;
            if (type == 'zombie' || type == 'foraging') {
                layer = 0;
                shift = false;
            }
            if (this.overlays[type] == 0) {
                this.overlays[type] = null;
                WorldMapService.viewer.addTiledImage({
                    tileSource: this.getMapRoot() + type + "/layer" + layer + ".dzi",
                    opacity: 1,
                    x: p.x,
                    y: p.y,
                    width: width,
                    success: (function (obj) {
                        if (this.overlays[type] == 'delete') {
                            WorldMapService.viewer.world.removeItem(obj.item);
                            this.overlays[type] = 0;
                        } else {
                            this.overlays[type] = obj.item;
                            if (shift) {
                                let clip_list = Map.shiftClipList(this.clip_list, this.info[type].scale, layer);
                                this.overlays[type].setCroppingPolygons(clip_list);
                            } else {
                                let clip_list = Map.shiftClipList(this.clip_list, this.info[type].scale, 0);
                                this.overlays[type].setCroppingPolygons(clip_list);
                            }
                        }
                    }).bind(this),
                    error: (function (e) {
                        if (['delete', 0, null].includes(this.overlays[type])) {
                            this.overlays[type] = 0;
                        }
                    }).bind(this),
                });
            }
        }
    }

    _unload(type, layer) {
        if (WorldMapService.viewer) {
            if (type == 'base') {
                if (layer < this.layers && this.tiles[layer] != 0) {
                    if ([null, 'delete'].includes(this.tiles[layer])) {
                        this.tiles[layer] = 'delete';
                    } else {
                        WorldMapService.viewer.world.removeItem(this.tiles[layer]);
                        this.tiles[layer] = 0;
                    }
                }
                return
            }
            if (this.overlays[type] != 0) {
                if ([null, 'delete'].includes(this.overlays[type])) {
                    this.overlays[type] = 'delete';
                } else {
                    WorldMapService.viewer.world.removeItem(this.overlays[type]);
                    this.overlays[type] = 0;
                }
            }
        }
    }

    loadMapInfo(type) {
        let xhttp = new XMLHttpRequest();
        xhttp.open("GET", this.getMapRoot() + type + "/map_info.json", false);
        try {
            xhttp.send(null);
        } catch (error) {
        } 
        if (xhttp.status === 200) {
            let info = JSON.parse(xhttp.responseText);
            info.scale = 1 << info.skip;
            this.info[type] = info;
        }
    }

    init() {
        this.layers = WorldMapService.TOTAL_LAYERS;
        this.tiles = Array(this.layers).fill(0);
        this.info = {};
        this.loadMapInfo('base');
        if (this.info.base) {
            this.w = this.info.base.w * this.info.base.scale;
            this.h = this.info.base.h * this.info.base.scale;
            this.scale = this.info.base.scale;
            this.x0 = this.info.base.x0;
            this.y0 = this.info.base.y0;
            this.sqr = this.info.base.sqr;
            this.cell_rects = this.info.base.cell_rects;
            for (let type of ['zombie', 'foraging', 'room', 'objects']) {
                this.loadMapInfo(type);
                this.overlays[type] = 0;
            }
        }
    }

    setBaseLayer(layer) {
        let start = 0;
        if (this.is_base) {
            start = 1;
        }
        for (let i = start; i < this.layers ; i++) {
            if (i > layer) {
                this._unload('base', i);
            }
        }
        for (let i = start; i <= layer && i < this.layers ; i++) {
            this._load('base', i);
        }
    }

    setOverlayLayer(overlay, layer) {
        for (let type of ['zombie', 'foraging', 'room', 'objects']) {
            if (overlay[type]) {
                if (!['zombie', 'foraging'].includes(type)) {
                    if (layer != this.overlay_layer) {
                        this._unload(type, layer);
                    }
                }
                this._load(type, layer);
            } else {
                this._unload(type, layer);
            }
        }
        this.overlay_layer = layer;
    }

    destroy() {
        this.setBaseLayer(-1);
        this.setOverlayLayer({}, 0);
    }
}



export {WorldMapService}
export default [
  {
    "name": "LouisvillePD",
    "tier": 5,
    "x1": 12000,
    "y1": 1200,
    "x2": 12700,
    "y2": 1950,
    "toxic": false
  },
  {
    "name": "LouisvilleMallArea",
    "tier": 5,
    "x1": 12700,
    "y1": 1200,
    "x2": 15000,
    "y2": 1950,
    "toxic": false
  },
  {
    "name": "Louisville",
    "tier": 4,
    "x1": 11700,
    "y1": 900,
    "x2": 15000,
    "y2": 4200,
    "nested": true,
    "toxic": false
  },
  {
    "name": "CC",
    "tier": 1,
    "x1": 11100,
    "y1": 8700,
    "x2": 11400,
    "y2": 9300,
    "toxic": false
  },
  {
    "name": "Muldraugh",
    "tier": 1,
    "x1": 9900,
    "y1": 8400,
    "x2": 12300,
    "y2": 11400,
    "toxic": false
  },
  {
    "name": "WestPointWest",
    "tier": 1,
    "x1": 10220,
    "y1": 6600,
    "x2": 11850,
    "y2": 7800,
    "toxic": false
  },
  {
    "name": "WestPointEast",
    "tier": 2,
    "x1": 11850,
    "y1": 6600,
    "x2": 12900,
    "y2": 7800,
    "toxic": false
  },
  {
    "name": "Riverside",
    "tier": 1,
    "x1": 5400,
    "y1": 5100,
    "x2": 7800,
    "y2": 6300,
    "toxic": false
  },
  {
    "name": "Rosewood",
    "tier": 1,
    "x1": 7500,
    "y1": 10800,
    "x2": 9300,
    "y2": 12600,
    "toxic": false
  },
  {
    "name": "MarchRidge",
    "tier": 1,
    "x1": 9600,
    "y1": 12300,
    "x2": 10500,
    "y2": 13500,
    "toxic": false
  },
  {
    "name": "InsidePetro",
    "tier": 5,
    "x1": 10930,
    "y1": 12100,
    "x2": 11110,
    "y2": 12330,
    "toxic": false
  },
  {
    "name": "Petroville",
    "tier": 4,
    "x1": 10500,
    "y1": 11400,
    "x2": 11400,
    "y2": 13500,
    "nested": true,
    "toxic": false
  },
  {
    "name": "LakeIvy",
    "tier": 2,
    "x1": 8700,
    "y1": 9300,
    "x2": 9600,
    "y2": 10800,
    "toxic": false
  },
  {
    "name": "FortRedstone",
    "tier": 2,
    "x1": 5400,
    "y1": 11700,
    "x2": 6000,
    "y2": 12300,
    "toxic": false
  },
  {
    "name": "RavenCreekPDMilitaryHospital",
    "tier": 5,
    "x1": 3000,
    "y1": 11100,
    "x2": 3670,
    "y2": 11950,
    "toxic": false
  },
  {
    "name": "RavenCreekEntrance",
    "tier": 4,
    "x1": 3670,
    "y1": 11100,
    "x2": 4183,
    "y2": 11950,
    "toxic": false
  },
  {
    "name": "RavenCreek",
    "tier": 3,
    "x1": 3000,
    "y1": 11100,
    "x2": 5400,
    "y2": 13500,
    "nested": true,
    "toxic": false
  },
  {
    "name": "EerieIrvington",
    "tier": 5,
    "x1": 11161,
    "y1": 17788,
    "x2": 11700,
    "y2": 18299,
    "toxic": false
  },
  {
    "name": "EeriePowerPlant",
    "tier": 3,
    "x1": 9900,
    "y1": 13879,
    "x2": 10966,
    "y2": 15292,
    "toxic": false
  },
  {
    "name": "EerieCapitol",
    "tier": 4,
    "x1": 8970,
    "y1": 16600,
    "x2": 9600,
    "y2": 17300,
    "toxic": false
  },
  {
    "name": "EerieMilitaryBase",
    "tier": 3,
    "x1": 8101,
    "y1": 17063,
    "x2": 8527,
    "y2": 17610,
    "toxic": false
  },
  {
    "name": "EerieCountry",
    "tier": 2,
    "x1": 7200,
    "y1": 13500,
    "x2": 12300,
    "y2": 18300,
    "nested": true,
    "toxic": false
  },
  {
    "name": "BigBearLakeMili",
    "tier": 5,
    "x1": 4800,
    "y1": 6900,
    "x2": 5556,
    "y2": 7160,
    "toxic": false
  },
  {
    "name": "BigBearLakeWest",
    "tier": 4,
    "x1": 5000,
    "y1": 7800,
    "x2": 5700,
    "y2": 8200,
    "toxic": false
  },
  {
    "name": "BigBearLake",
    "tier": 3,
    "x1": 5903,
    "y1": 7475,
    "x2": 6611,
    "y2": 7855,
    "nested": true,
    "toxic": false
  },
  {
    "name": "BigBearLake",
    "tier": 2,
    "x1": 4800,
    "y1": 6900,
    "x2": 6900,
    "y2": 8400,
    "nested": true,
    "toxic": false
  },
  {
    "name": "Chestown",
    "tier": 2,
    "x1": 4500,
    "y1": 6600,
    "x2": 4800,
    "y2": 6900,
    "toxic": false
  },
  {
    "name": "LCBunker",
    "tier": 5,
    "x1": 17400,
    "y1": 6300,
    "x2": 18300,
    "y2": 6900,
    "toxic": false
  },
  {
    "name": "LCDowntown",
    "tier": 4,
    "x1": 16800,
    "y1": 6300,
    "x2": 17400,
    "y2": 6900,
    "toxic": false
  },
  {
    "name": "LC",
    "tier": 2,
    "x1": 15000,
    "y1": 6300,
    "x2": 18300,
    "y2": 8100,
    "nested": true,
    "toxic": false
  },
  {
    "name": "Taylorsville",
    "tier": 2,
    "x1": 9000,
    "y1": 6300,
    "x2": 10220,
    "y2": 7200,
    "toxic": false
  },
  {
    "name": "Grapeseed",
    "tier": 2,
    "x1": 7200,
    "y1": 11100,
    "x2": 7500,
    "y2": 11400,
    "toxic": false
  },
  {
    "name": "DirkerHard",
    "tier": 5,
    "x1": 1500,
    "y1": 1800,
    "x2": 7658,
    "y2": 4807,
    "toxic": true
  },
  {
    "name": "DirkerMed",
    "tier": 4,
    "x1": 7659,
    "y1": 1800,
    "x2": 10498,
    "y2": 4025,
    "toxic": true
  },
  {
    "name": "DirkerEasy",
    "tier": 3,
    "x1": 7659,
    "y1": 4025,
    "x2": 10498,
    "y2": 5012,
    "toxic": true
  },
  {
    "name": "OldStPaulo",
    "tier": 2,
    "x1": 3350,
    "y1": 7610,
    "x2": 4800,
    "y2": 10450,
    "toxic": false
  },
  {
    "name": "LVshipping",
    "tier": 2,
    "x1": 12250,
    "y1": 4500,
    "x2": 12750,
    "y2": 4950,
    "toxic": false
  },
  {
    "name": "LVairport",
    "tier": 2,
    "x1": 12750,
    "y1": 4200,
    "x2": 13500,
    "y2": 4800,
    "toxic": false
  },
  {
    "name": "OaksdaleU",
    "tier": 3,
    "x1": 12000,
    "y1": 11370,
    "x2": 12810,
    "y2": 12050,
    "toxic": false
  },
  {
    "name": "Nettle",
    "tier": 2,
    "x1": 6470,
    "y1": 8880,
    "x2": 7280,
    "y2": 9700,
    "toxic": false
  },
  {
    "name": "RosewoodX",
    "tier": 2,
    "x1": 7970,
    "y1": 10820,
    "x2": 8790,
    "y2": 11410,
    "toxic": false
  }
];
  
import React, { Component } from 'react';

import {WorldMapService} from "../services/WorldMapService/WorldMapService";
import {item_id_to_description} from "../services/Util";
import BusStops from "../services/WorldMapService/bus_stop";
import TownNames from "../services/WorldMapService/town_names";

class WorldMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overlay: 'room',
            grid: false,
            zones: false,
            pois: true,
            layer: 0,
            selectedTab: 'explorer',
            items: [],
            filteredItems: [],
            selectedSellers: [],
        };
        this.initCalled = false;
        this.worldMapService = new WorldMapService();
    }
 
    componentDidMount() {
        this.setState({items: this.props.selling});

        if (!this.initCalled) {
            this.worldMapService.init(() => {
                WorldMapService.loadModMaps();
                WorldMapService.toggleOverlay('room');
                WorldMapService.togglePois(true);
                this.addShopPois(); 
                this.addBusStops(); 
                this.addTownNames();
            });

            this.initCalled = true;
        }
    }

    componentWillUnmount() {
        WorldMapService.removeModMaps();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shops !== this.props.shops) {
            this.addShopPois(); 
            this.addBusStops();
            this.addTownNames();
        }
        if (prevProps.selling !== this.props.selling) {
            this.setState({items: this.props.selling})
            this.setState({filteredItems: this.props.selling.slice(0, 15)});
        }
    }

    addShopPois = () => {
        const topLeft = { x: 11104, y: 8770 };
        const bottomRight = { x: 11292, y: 8997 };

        const filteredShops = this.props.shops.filter(({ x, y }) => {
            return x >= topLeft.x && x <= bottomRight.x && y >= topLeft.y && y <= bottomRight.y;
        });

        filteredShops.forEach(shop => {
            WorldMapService.addPoi(`shop-${shop.id}`, shop.x, shop.y, 'yellow',`${shop.owner}'s shop`, 'shop', shop.z);
        });
    }

    addBusStops = () => {
        Object.keys(BusStops).forEach(key => {
            const busStop = BusStops[key];
            const image = new Image();
            image.src = "/logo512.png";
            WorldMapService.addPoi('bus-stop-' + key, busStop.x, busStop.y, 'green', `${key} Bus Stop`, `${key} Bus Stop`, 0, 0, image, 'yellow')
        });
    }

    addTownNames = () => {
        Object.keys(TownNames).forEach(key => {
            const TownName = TownNames[key];

            WorldMapService.addPoi('town-name-' + key, TownName.x, TownName.y, 'purple', key, key, 0, 0)
        });
    }

    handleLayerChange = (layer) => {
        WorldMapService.selectLayer(layer);
        this.setState({layer})
    };

    handleToggleGrid = (event) => {
        WorldMapService.toggleGrid();
        this.setState({grid: event.target.checked})
    };

    handleToggleZones = (event) => {
        WorldMapService.toggleZones();
        this.setState({zones: event.target.checked})
    };

    handleOverlayChange = (event) => {
        this.handleLayerChange(0);
        WorldMapService.toggleOverlay(this.state.overlay);
        WorldMapService.toggleOverlay(event.target.value);
        this.setState({overlay: event.target.value})
    };

    handleTogglePois = (event) => {
        this.setState({pois: event.target.checked});
        WorldMapService.togglePois(event.target.checked);
    };

    handleTabSelection = (tab) => this.setState({selectedTab: tab});

    getTabClass = (tab) => this.state.selectedTab === tab ? "is-active" : '';

    handleFilterChange = (event) => {
        const filteredItems = this.state.items.filter((item) => {
            if (event.target.value.length < 2) return true;
        
            const value = item_id_to_description(item.item).toLowerCase() + ' ' + item.sellers.join(' ').toLowerCase();
    
            return (value.search(event.target.value.toLowerCase()) >= 0) && !item.item.toLowerCase().includes("moveables");
        }).slice(0,15);

        this.setState({filteredItems})
    };

    itemFilterFunction = (item) => {
        if (this.state.filter.length < 2) return true;
        
        const value = item_id_to_description(item.item).toLowerCase() + ' ' + item.sellers.join(' ').toLowerCase();

        return (value.search(this.state.filter.toLowerCase()) >= 0) && !item.item.toLowerCase().includes("moveables");
    }

    toggleSelectedSellers = (sellerNames) => {
        const { selectedSellers } = this.state;
    
        const allIncluded = sellerNames.every(seller => selectedSellers.includes(seller));
    
        let updatedSellers;
    
        if (allIncluded) {
            updatedSellers = selectedSellers.filter(seller => !sellerNames.includes(seller));
        } else {
            updatedSellers = [...new Set([...selectedSellers, ...sellerNames])];
        }
    
        this.setState({ selectedSellers: updatedSellers });
        this.updateSelectedSellers(updatedSellers);
    }

    toggleSelectedSeller = (sellerName) => {
        let selectedSellers;
        if(!this.state.selectedSellers.includes(sellerName)) {
            selectedSellers = [...new Set([...this.state.selectedSellers, sellerName])];
        } else {
            selectedSellers = this.state.selectedSellers.filter(owner => owner !== sellerName);
        }

        this.setState({selectedSellers})

        this.updateSelectedSellers(selectedSellers);
    }

    updateSelectedSellers = (selectedSellers) => {
        this.props.shops.forEach(shop => {
            if(selectedSellers.includes(shop.owner)) {
                WorldMapService.updateGroupedPoi(`shop-${shop.id}`, {color: 'blue'});
            } else {
                if(selectedSellers.length) {
                    WorldMapService.updateGroupedPoi(`shop-${shop.id}`, {color: 'rbg(255, 255, 0, 0.5)'});
                } else {
                    WorldMapService.updateGroupedPoi(`shop-${shop.id}`, {color: 'yellow'});
                }
            }
        });
    }

    render() {
        return (
            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
                <div className="is-fixed world-map-control-board">
        
                    <div className="tabs is-centered is-medium mb-5">
                        <ul>
                            <li onClick={() => this.handleTabSelection('explorer')} className={this.getTabClass('explorer')}><a>Explorer</a></li>
                            <li onClick={() => this.handleTabSelection('economy')} className={this.getTabClass('economy')}><a>Economy</a></li>
                        </ul>
                    </div>
                    {this.state.selectedTab === 'explorer' && <div className="explorer-tab">
                        <div>
                            <label className='has-text-white mt-5 mb-3 has-text-centered is-size-5'>POI's</label>
                            <div className="switch-container is-flex is-justify-content-center is-align-items-center mb-5">
                                <span className="switch-label mr-3 has-text-white is-size-5">Off</span>
                                <label className="switch">
                                    <input type="checkbox" checked={this.state.pois} onChange={this.handleTogglePois}/>
                                    <span className="slider"></span>
                                </label>
                                <span className="switch-label ml-3 has-text-white is-size-5">On</span>
                            </div>
                            
                            <label className='has-text-white mt-5 mb-3 has-text-centered is-size-5'>Zones</label>
                            <div className="switch-container is-flex is-justify-content-center is-align-items-center mb-5">
                                <span className="switch-label mr-3 has-text-white is-size-5">Off</span>
                                <label className="switch">
                                    <input type="checkbox" checked={this.state.zones} onChange={this.handleToggleZones}/>
                                    <span className="slider"></span>
                                </label>
                                <span className="switch-label ml-3 has-text-white is-size-5">On</span>
                            </div>

                            <label className='has-text-white mt-5 mb-3 has-text-centered is-size-5'>Grid</label>
                            <div className="switch-container is-flex is-justify-content-center is-align-items-center mb-5">
                                <span className="switch-label mr-3 has-text-white is-size-5">Off</span>
                                <label className="switch">
                                    <input type="checkbox" checked={this.state.grid} onChange={this.handleToggleGrid}/>
                                    <span className="slider"></span>
                                </label>
                                <span className="switch-label ml-3 has-text-white is-size-5">On</span>
                            </div>

                            <label className='has-text-white mt-5 is-size-5'>Select Overlay</label>
                            <div className="select is-primary mb-5">
                                <select onChange={this.handleOverlayChange} disabled={this.state.view == 'top'}>
                                    <option value="room">Room Types</option>
                                    <option value="zombie">Zombie Heat Map</option>
                                    <option value="foraging">Foraging</option>
                                    <option value="objects">Objects</option>
                                </select>
                            </div>
                        </div>
                        {this.state.overlay === 'foraging' && (
                            <div className='p-3'>
                                <div className='is-flex is-align-items-center mt-2'>
                                    <div className="legend ml-3 mr-3" style={{ backgroundColor: "#fff" }} />
                                    Road
                                </div>
                                <div className='is-flex is-align-items-center mt-2'>
                                    <div className="legend ml-3 mr-3" style={{ backgroundColor: "#00f" }} />
                                    Urban Area
                                </div>
                                <div className='is-flex is-align-items-center mt-2'>
                                    <div className="legend ml-3 mr-3" style={{ backgroundColor: "#0ff" }} />
                                    Trailer Park
                                </div>
                                <div className='is-flex is-align-items-center mt-2'>
                                    <div className="legend ml-3 mr-3" style={{ backgroundColor: "#ff0" }} />
                                    Vegetation
                                </div>
                                <div className='is-flex is-align-items-center mt-2'>
                                    <div className="legend ml-3 mr-3" style={{ backgroundColor: "#0f0" }} />
                                    Forest
                                </div>
                                <div className='is-flex is-align-items-center mt-2'>
                                    <div className="legend ml-3 mr-3" style={{ backgroundColor: "#080" }} />
                                    Deep Forest
                                </div>
                                <div className='is-flex is-align-items-center mt-2'>
                                    <div className="legend ml-3 mr-3" style={{ backgroundColor: "#f0f" }} />
                                    Farmland
                                </div>
                                <div className='is-flex is-align-items-center mt-2'>
                                    <div className="legend ml-3 mr-3" style={{ backgroundColor: "#f00" }} />
                                    Farm
                                </div>
                            </div>
                    )}
                    {this.state.overlay === 'zombie' && (
                        <div className='p-3 is-flex is-flex-direction-column is-align-items-center has-text-white'>
                            <span className='mb-3'>Dense</span>
                            <div className="zombie-legend"></div>
                            Less Dense
                        </div>
                    )}
                    {this.state.overlay === 'room' && (
                        <div className='p-3 is-flex is-flex-direction-column is-align-items-center has-text-white'>
                                {[...Array(WorldMapService.TOTAL_LAYERS - 1).keys()].reverse().map((layer) => (
                                    <div
                                        key={layer}
                                        onClick={() => this.handleLayerChange(layer)}
                                        className={`layer-square mx-3 is-clickable ${this.state.layer === layer ? 'active' : ''}`}
                                    ></div>
                                ))}
                            <label className='has-text-white is-size-5'>Layer</label>
                        </div>
                    )}
                    </div>}
                    {this.state.selectedTab === 'economy' && 
                        <div className="economy-tab" style={{maxWidth: '400px'}}>
                            <input className="input" type="text" placeholder="Search..." onChange={this.handleFilterChange}/>
                            <div className='is-flex is-flex-wrap-wrap'>
                            {this.state.selectedSellers.length > 1 && <span 
                                className={`tag ml-2 mt-2 is-clickable is-dark`}
                                onClick={() => this.toggleSelectedSellers(this.state.selectedSellers)}>Clear</span>}
                                {this.state.selectedSellers.map((seller, index) => <span 
                                    key={seller + index} 
                                    className={`tag ml-2 mt-2 is-clickable ${this.state.selectedSellers.includes(seller) ? 'is-info' : 'is-dark'}`}
                                    onClick={() => this.toggleSelectedSeller(seller)}>{seller}</span>
                                )}
                            </div>
                            <table className="table is-bordered is-fullwidth mt-5">
                                <tbody>
                                    {this.state.filteredItems.map((item, index) => <tr key={item + index}>
                                        <td className='is-clickable' onClick={() => this.toggleSelectedSellers(item.sellers)}>{item_id_to_description(item.item)}</td>
                                        <td>
                                            {[...new Set(item.sellers)].map((seller, index) => <span 
                                                key={seller + index} 
                                                className={`tag ml-2 is-clickable ${this.state.selectedSellers.includes(seller) ? 'is-info' : 'is-dark'}`}
                                                onClick={() => this.toggleSelectedSeller(seller)}>{seller}</span>
                                            )}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div style={{ flex: "1 1 auto" }}>
                    <div id="map_div" allowFullScreen="" style={{ height: "96vh" }}></div>
                </div>
            </div>
        );
    }
}

export default WorldMap;
